import React, { Component, useContext, useEffect, useState } from 'react';

import Breadcrumb from './Breadcrumb'
import ReactDOM from 'react-dom'
import { Link, useParams, useHistory } from 'react-router-dom'
import utilsOrders from '../../../../../MyConns/_Utils/utilsOrders';
import MyDateTime from '../../../../MySections/TimeSections/MyDateTime';
import SucessToast from '../../../../MySections/Toasts/SucessToast';
import OrderFunctions from '../../../../../MyFunctions/OrderFunctions';
import PrintingStyle from '../../../../../MyFunctions/PrintingStyle';

import OrderStatuselect from '../../OrdersList-list/OrderStatuselect';
import { Button } from '@mui/material';
import PrintTemplate from 'react-print';
import { display } from '@mui/system';
import InvoicePrint from './InvoicePrint';
import TextBoxSet from '../../../../MySections/PopUps/TextBoxSet';
import parse from 'html-react-parser';
import InvoiceTable from './InvoiceTable';
import InvoiceMobiTable from './InvoiceMobiTable';

import Scroll from 'react-scroll';

import { MobiOrNotContext } from '../../../../MyContexts/MobiOrNotContext';

import UpdateOrderDetails from '../../../../MySections/PopUps/UpdateOrderDetails';
import MySpinner_Wait from '../../../../MySections/DTechComps/MySpinner_Wait';
import Translator from '../../../../../MyFunctions/Translator';
import InputSet from '../../../../MySections/PopUps/InputSet';
import DeliveryDetails from '../../DeliveryDetails/DeliveryDetails';
import PopUpTheComp from '../../../../MySections/DTechComps/PopUpTheComp';
import { UserContext } from '../../../../MyContexts/Users_Context';
import ZikoyOnOrder from '../../../../MySections/PopUps/ZikoyOnOrder';
import LogsOrder from '../../../../MySections/PopUps/LogsOrder';
import { ArrowDropDownCircle, ArrowUpward, KeyboardCapslockOutlined, LibraryBooks } from '@mui/icons-material';
import myFunctions from '../../../../../myFunctions';
import ClientUserItem from '../../../ClientUsers/ClientUserItem';
import GoBackCheck from '../../../../MySections/PopUps/GoBackCheck';

var scroll = Scroll.scroller;
var scroller = Scroll.animateScroll;

const MobiContent = (props) => {

    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [user, setUser] = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [theOrder, setTheOrder] = useState([]);
    const [times, setTimes] = useState([]);
    const [printMe, setPrintMe] = useState([false]);
    const [thePopUp, setThePopUp] = useState("");
    const [theSecondPop, setTheSecondPop] = useState("");
    const [arabic, setArabic] = useState("عربيه");
    const [errMsg, setErrMsg] = useState("");
    const [scrolBtnShow, setScrolBtnShow] = useState(true);
    const [timerInterv, setTimerInterv] = useState(null);

    let idParam = useParams();
    let inactivityTimer;

    const history = useHistory();


    useEffect(() => {
        setTheScroller();

    }, [])

    useEffect(() => {

        if (user.permiss) { loadTheOrder(); }
    }, [user])

    const setTheScroller = () => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }

    const handleScroll = () => {

        const currentScrollY = window.scrollY;
        if (parseInt(currentScrollY) > 500) {

            if (scrolBtnShow != "scrolBtnShow") { setScrolBtnShow("scrolBtnShow") };
        }
        else {
            setScrolBtnShow("");

        }
    }

    const loadTheOrder = async () => {
        if (idParam.id) {
            let Orderloaded = await utilsOrders.getOrder(idParam.id);

            if (Orderloaded.errMsg) {
                setErrMsg(Orderloaded.errMsg);
            }
            else {

                if (Orderloaded.ord.orderStatus === "ממתין לתגובה" &&
                    (user.permiss === "restAdmin"
                        || user.permiss === "restUser")) {
                    await setNewStatus(idParam.id, "(צפייה) בהכנה");
                }

                setTheOrder(Orderloaded);
                getTimes(Orderloaded)


                setLoading(false);
            }
        }
        else {
            history.push("/Orders");
        }

    }

    const goToOrders = () => {
        myFunctions.setGetCurrnetTab("Set", "ordLstBtn", 6)
        history.push('/Orders');
    }

    let popCheckActive = false;

    const resetInactivityTimer = () => {
        if (!popCheckActive) {
            clearTimeout(inactivityTimer);
            inactivityTimer = setTimeout(() => {
                // Redirect to /Orders after 3 minutes of inactivity
                popCheckActive = true;
                setTheSecondPop(
                    <GoBackCheck title={'עדיין כאן?'}
                        successFunc={() => { goToOrders(); }}
                        cancelFunc={() => {
                            popCheckActive = false;
                            resetInactivityTimer()
                        }}
                    />)
            }, 180000); // 3 minutes in milliseconds
            // }, 1000); // 3 minutes in milliseconds
        }
    };

    useEffect(() => {
        if (user.permiss !== "admin") {
            // Set up event listeners for user activity
            const activityEvents = ['mousemove', 'keydown', 'scroll', 'click'];
            activityEvents.forEach((event) => {
                window.addEventListener(event, resetInactivityTimer);
            });

            // Initialize the timer
            resetInactivityTimer();

            // Cleanup event listeners and timer on unmount
            return () => {
                clearTimeout(inactivityTimer);
                activityEvents.forEach((event) => {
                    window.removeEventListener(event, resetInactivityTimer);
                });
            };
        }
    }, [history]);


    const getTimes = (ord) => {
        setInterval(() => {
            let theItm = MyDateTime.getTimeLeftTilThen(ord.ord.dateCreated, ord.ord.OrderTimes);
            setTimes(theItm)

        }, 1000);
    }



    const updateTheOrder = async () => {

        let updteme = await utilsOrders.updateOrder(theOrder.ord._id, theOrder);
        loadTheOrder();
        SucessToast({ position: "toast-bottom-right", text: "עודכן בהצלחה!" });
    }

    const showClientDetails = () => {
        if (theOrder.usr) {
            setThePopUp(
                <PopUpTheComp
                    closePop={() => { setThePopUp() }}
                    cssStyl={"popSetter"}
                    popDiagStyle={"popMax popMax800 noSidesPadding"}
                    errorMsg={""}
                    okClick={() => { setThePopUp() }}
                    btnClick1={true}
                    Title={["פרטי לקוח"]}
                    titleCss={"centerText"}
                    CancelRounded={"parentX"}
                    theInsideComp={
                        <ClientUserItem noBack={true} clentId={`${theOrder.usr._id ? theOrder.usr._id : ""}`} />}
                />
            )
        }
    }

    const getAddress =
        (theOrder.ord?.OrderType !== "Take") ?
            <>
                <div className={"bold big "}>
                    &nbsp;
                    {theOrder.ord?.deliveryAddress.street}
                    &nbsp;

                    {(!theOrder.ord?.deliveryAddress.street.endsWith(theOrder.ord?.deliveryAddress.num)) ?
                        theOrder.ord?.deliveryAddress.num
                        : ""}
                    &nbsp;
                    &nbsp;
                    {(theOrder.ord?.deliveryAddress.appartment) ?
                        `דירה: ${theOrder.ord?.deliveryAddress.appartment}`
                        : ""}
                    &nbsp;
                    &nbsp;
                    {(theOrder.ord?.deliveryAddress.ent) ?
                        `כניסה: ${theOrder.ord?.deliveryAddress.ent}`
                        : ""}
                    &nbsp;
                    &nbsp;
                    {(theOrder.ord?.deliveryAddress.flor) ?
                        `קומה: ${theOrder.ord?.deliveryAddress.flor}`
                        : ""}
                    &nbsp;
                    &nbsp;
                    {theOrder.ord?.deliveryAddress.city || "אשדוד"}


                </div>
                <div className={"bold big maxTxtAreaHeight"}>
                    {(theOrder.ord?.deliveryAddress.remarks) ?
                        `הערות: ${theOrder.ord?.deliveryAddress.remarks}`
                        : ""}
                    &nbsp;
                    &nbsp;
                </div>
            </> : "";



    const clientDetails =
        <>
            <div className={"spac specialRow"}>
                <div className={"bold goRtl"}>שם הלקוח: {theOrder.usr?.firstName}  {theOrder.usr?.lastName}</div>
                <div className={"bold goRtl"}>טלפון: {theOrder.usr?.phone}</div>
                {theOrder.usr?.email != "" ? <div className={"bold goRtl"}>אימייל: {theOrder.usr?.email}</div> : ""}
            </div>

            {(user.permiss === "admin") && !(theOrder.usr?.firstName + " " + theOrder.usr?.lastName).includes(" - משוחזר") &&
                <div style={{ position: 'relative', textAlign: 'left' }}>
                    <Button className={"btnMediumMnu"} variant="contained" onClick={() => showClientDetails()}>
                        פרטי לקוח
                    </Button>
                </div>

            }
        </>;


    const popUpdateOrder = () => {
        setThePopUp(
            <UpdateOrderDetails Title={"עריכת פרטים"}
                theOrder={theOrder} inLabel={""}
                okClick={(theOrderBk) => popMe(theOrderBk)}
                cancelClick={() => setThePopUp()}
                errorMsg={""}
            />)


    }
    const popZikoyOrder = () => {
        setThePopUp(
            <ZikoyOnOrder Title={"יצירת זיכוי"}
                theOrder={theOrder}
                inLabel={"תאור הזיכוי"}
                inLabel2={"סכום הזיכוי"}
                okClick={(theOrderBk) => { popMe(theOrderBk); setThePopUp() }}
                cancelClick={() => setThePopUp()}
                errorMsg={""}
            />)


    }

    const popMe = (theOrderBk) => {
        // setTheOrder(theOrderBk)
        // // console.log(theOrderBk)
        // updateTheOrder();
    }

    const printIt = () => {
        setPrintMe(true)

        var content = document.getElementById("printingCloser");
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML + PrintingStyle.mediaprint());
        pri.document.close();
        pri.focus();
        pri.print();


        // window.print()
        setPrintMe(false)
    }



    const ShowInArab = () => {
        if (arabic === "عربيه") {
            setArabic("עברית");
            setTheOrder(Translator.Arabic(theOrder));

        } else {
            setArabic("عربيه");
            setTheOrder(Translator.BackToHeb(theOrder));
        }
    }


    const setNewStatus = async (orderId, statBack) => {

        if (statBack !== "מבוטל") {
            let updteme = await utilsOrders.updateStatus(orderId, statBack);
            loadTheOrder();
        }
        else {
            cancelOrderapproval(orderId);
        }

    }
    const cancelOrderapproval = (orderId) => {

        setThePopUp(<InputSet ContentText={`מהי סיבת הביטול?`}
            okClick={(inputTxt) => okCancel(inputTxt, orderId)}
            cancelClick={() => { setThePopUp() }}
            errorMsg={"חובה להזין סיבה לביטול"} />)

        const okCancel = async (cancelReason, orderId) => {
            let updteme = await utilsOrders.updateStatusToCancel(orderId,
                "מבוטל", cancelReason);
            loadTheOrder();
        }

    }

    const setRemarks = () => {
        setThePopUp(
            <TextBoxSet Title={"הערות הזמנה"}
                textFirst={theOrder.ord.orderRemarks} inLabel={""}
                okClick={(textBack) => setRemarksApprov(textBack)}
                cancelClick={() => setThePopUp()}
                errorMsg={""}
            />)
    }


    const setRemarksApprov = (textBack) => {
        // alert(textBack)


        theOrder.ord.orderRemarks = textBack;

        setTheOrder(theOrder);
        getTimes(theOrder)

    }

    const addOrderItem = () => {
        setThePopUp(
            // <RestMnu buisMenu={restPage.buisMenu} nameBuis={restPage.buissName} restPage={restPage}
            //     doDeliver={temp_doDeliver}
            //     doTakeAway={temp_doTakeAway}
            //     setShowHideMoreDet={() => setShowHideMoreDet("showOnLeft")}
            //     dispOnlyDelivery={dispOnlyDelivery} BuisId={restPage._id}
            //     setDispOnlyDelivery={() => setDispOnlyDelivery(!dispOnlyDelivery)} />

        )
    }
    const popOrderLog = (orderNUM) => {
        setThePopUp(
            <PopUpTheComp
                closePop={() => { setThePopUp() }}
                cssStyl={"popSetter"}
                popDiagStyle={"popMax popMax800"}
                errorMsg={""}
                okClick={() => { setThePopUp() }}
                btnClick1={true}
                Title={["לוג הזמנה " + orderNUM]}
                titleCss={"centerText"}
                theInsideComp={
                    <LogsOrder cssStyl={"popSetter"}
                        ordNum={orderNUM}

                        cancelClick={() => setThePopUp()}
                        popDiagStyle={" popMax popMax800"}
                    />}
            />



        )
    }

    const PopDeliveryDetails = (orderNUM, buisName, AddrsTo) => {
        setThePopUp(
            <PopUpTheComp
                closePop={() => { setThePopUp() }}
                cssStyl={"OrderDispPop"}
                popDiagStyle={"OrderDispValPop"}
                errorMsg={""}
                okClick={() => { setThePopUp() }}
                btnClick1={true}
                Title={["פרטי השליחות"]}
                theInsideComp={
                    <DeliveryDetails cssStyl={"OrderDisplayPop"}
                        cancelClick={() => setThePopUp()}
                        okClick={() => { setThePopUp() }}
                        Title={[""]}
                        ordNum={orderNUM}
                        buisName={buisName}
                        AddrsTo={AddrsTo}
                    />}
            />

        )
    }

    const scrolUp = (scInt) => {
        scroller.scrollToTop(scInt);
    }

    const topBtnsROw = () => {
        return (
            <>
                <div className="col-12 overflowHid">
                    <div className="col-9 onRight overflowHid noPadding">
                        <div className="col-stretch">

                            <div className={"onRight spaceMargeTopbig spaceMargeRight"}>
                                <Button className={"btnMediumMnu"} variant="contained" onClick={() => history.push('/Orders')}>
                                    לכל ההזמנות
                                </Button>
                            </div>
                        </div>

                        {/* <div className={"onLeft spaceMargeTopbig "}>
                    <Button className={"btnMediumMnu"} variant="contained" onClick={() => popUpdateOrder()}>
                        ערוך פרטים
                    </Button>
                </div> */}
                        {(user.permiss === "admin") &&
                            <div className="col-stretch">
                                <div className={"onRight spaceMargeTopbig spaceMargeRight"}>
                                    <Button className={"btnMediumMnu"} variant="contained" onClick={() => popZikoyOrder()}>
                                        זיכוי בהזמנה
                                    </Button>
                                </div>
                            </div>

                        }
                        <div className="col-stretch">
                            <div className={"onRight spaceMargeTopbig spaceMargeRight"}>
                                <Button className={"btnMediumMnu"} variant="contained" onClick={() => setRemarks()}>הערות</Button>
                            </div>

                        </div>


                        {theOrder.rst.arabic &&
                            <div className="col-stretch">
                                <div className={"onRight spaceMargeTopbig spaceMargeRight"}>
                                    <Button className={"btnMediumMnu"} variant="contained" onClick={() => ShowInArab()}>
                                        {arabic}
                                    </Button>
                                </div>
                            </div>}


                        {(user.permiss === "admin") &&
                            <>
                                <div className="col-stretch">
                                    <div className={"onRight spaceMargeTopbig spaceMargeRight"}>
                                        <Button className={"btnMediumMnu"} variant="contained"
                                            onClick={() => popOrderLog(theOrder.ord?.orderNUM)}
                                            startIcon={<LibraryBooks sx={{ color: 'white' }} />}
                                        >
                                        </Button>
                                    </div>
                                </div>
                                {/* <div className="col-stretch spaceMargeRight">
                                <Button className={"btnMediumMnu"} variant="contained" onClick={() => addOrderItem()}>+</Button>
                            </div> */}
                            </>
                        }

                    </div>

                    <div className="col-3 onLeft overflowHid noPadding noMargin">

                        <div className="col-stretch">

                            <div className={"onRight spaceMargeTopbig spaceMargeRight"}>
                                <Button className={"btnMediumMnu"} variant="contained" onClick={() => printIt()}>
                                    הדפס הזמנה
                                </Button>

                            </div>
                        </div>
                    </div>

                </div>


            </>

        )

    }


    return (
        loading ? <MySpinner_Wait /> :

            (errMsg !== "") ? <p>{errMsg}</p> :
                <>
                    <div className="no_print">
                        <div className="col-md-12 textRight">
                            <div className={`row no_print`}>
                                <div className="col-md-12 inBGWhite noPadding">
                                    <div className="col-12 textRight dirRight noPadding">
                                        <Breadcrumb />
                                    </div>

                                    <div className="col-12  noPadding">
                                        {topBtnsROw()}
                                        <div className="col-12">
                                            <div className="centerText fullRow">
                                                <h3 className={"spaceMargeTopbig"}>{`${theOrder.rst?.buissName}`}</h3>
                                            </div>

                                            <div className="col-md-12 overflowHid ordDetailsDiv">


                                                <div className="col-md-6 onRight noPadding">

                                                    <div className="col-md-12 onRight noPadding">

                                                        <div className="col-stretch onRight noPadding">
                                                            <h4 className="bold noPadding noMargin">
                                                                {(theOrder.ord?.OrderType === "Deliver") ? "משלוח" : "איסוף"}
                                                            </h4>
                                                        </div>
                                                        <div className="col-stretch onLeft spaceTop fullOnPrint ">
                                                            <h6>{`הזמנה #${theOrder.ord?.orderNUM}`}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 overflowHid noPadding">
                                                        <div className={"fullRow invoice-date"}>
                                                            {
                                                                (times.theTm) ?
                                                                    (times.theEnd) ?
                                                                        (
                                                                            (theOrder.ord?.orderStatus !== "סגור"
                                                                                && theOrder.ord?.orderStatus !== "מבוטל"
                                                                            ) ?
                                                                                <div>
                                                                                    <div className={"myButton noPadding"}
                                                                                        style={{ backgroundColor: times.theCol, color: times.theForCol, fontWeight: "bold" }}
                                                                                        id={theOrder.ord?._id + "_Count"}>
                                                                                        <div className={"fullRow"}>
                                                                                            <div className={"onRight spacePaddingRight"}>הסתיים הזמן לפני</div>
                                                                                            <div className={"onRight spaceMargeRight"}>
                                                                                                <div id={theOrder.ord?._id + "_Cnt"}>
                                                                                                    {times.theTm}<br />
                                                                                                    {/* {MyDateTime.getTimeLeftTilThen( theOrder.dateCreated,  theOrder.OrderTimes)} */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* {counterDown( theOrder._id)} */}
                                                                                    </div>
                                                                                </div> :
                                                                                "") :
                                                                        <div>
                                                                            <div className={"myButton noPadding"}
                                                                                style={{ backgroundColor: times.theCol, color: times.theForCol, fontWeight: "bold" }}
                                                                                id={theOrder.ord?._id + "_Count"}>
                                                                                <div className={"fullRow"}>
                                                                                    <div className={"onRight spacePaddingRight"}>זמן לביצוע</div>
                                                                                    <div className={"onRight spaceMargeRight"}>
                                                                                        <div id={theOrder.ord?._id + "_Cnt"}>
                                                                                            {times.theTm}<br />
                                                                                            {/* {MyDateTime.getTimeLeftTilThen( theOrder.dateCreated,  theOrder.OrderTimes)} */}
                                                                                        </div>
                                                                                        {/* {counterDown( theOrder._id)} */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    : ""
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className={"fullRow overflowHid spacePadBig ordsDisp"}>
                                                        <div className={"pointing onRight dirRight spaceMargeTopSmall"} style={{ width: "48%" }} >
                                                            <OrderStatuselect orderStatus={theOrder.ord?.orderStatus}
                                                                setOrderStatus={(statBack) => setNewStatus(theOrder.ord?._id, statBack)}
                                                            />
                                                        </div>
                                                        {(theOrder.ord?.OrderType !== "Take" && theOrder.ord?.sendiStatus && theOrder.ord?.sendiStatus.trim !== "") &&
                                                            <div className={"onLeft sendiStatus spaceTop"} style={{ width: "48%" }} >
                                                                {(theOrder.rst?.sendiRestName && theOrder.rst?.sendiRestName != "") ?
                                                                    <Button className={`btnDeliveryDetail2 ${myFunctions.getSendiColors(theOrder.ord?.sendiStatus)} dvStat`} variant="contained"
                                                                        onClick={() => PopDeliveryDetails(theOrder.ord.orderNUM, theOrder.rst.buissName,
                                                                            theOrder.ord.deliveryAddress.street + " " + theOrder.ord.deliveryAddress.num + " " + theOrder.ord.deliveryAddress.city)}>
                                                                        <span>{theOrder.ord.sendiStatus}</span>
                                                                    </Button> :
                                                                    <Button className={`btnDeliveryDetail2 dvStat`} variant="contained"
                                                                        onClick={() => { }}>
                                                                        <span>שליח פרטי</span>
                                                                    </Button>
                                                                }
                                                            </div>

                                                        }
                                                        {theOrder.ord?.orderStatus === "מבוטל" && theOrder.ord?.cancelReason ?
                                                            <div className={"col-md-12 overflowHid noPadding"}>
                                                                <div className={"col-stretch"}>
                                                                    <span className={"bold"}>סיבת הביטול: {theOrder.ord?.cancelReason}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            : ""}
                                                    </div>







                                                    <div className="col-md-12 overflowHid ordDetailsDiv">
                                                        {/* Invoice To */}
                                                        <div className="row align-items-center">
                                                            <div className={"col-md-12 onlyOnPrint"}>
                                                                <div className="invoice-address">
                                                                    {(theOrder.ord?.OrderType === "Deliver") ?
                                                                        <>
                                                                            {getAddress}
                                                                            <div className={"dottedLightGray"}></div>
                                                                        </>
                                                                        : ""}
                                                                    {clientDetails}
                                                                    <div className={"dottedLightGray"}></div>
                                                                </div>
                                                            </div>

                                                            <div className={"col-md-12"}>
                                                                <div className="invoice-address">
                                                                    {(theOrder.ord?.OrderType === "Deliver") ?
                                                                        <>
                                                                            {getAddress}
                                                                            <div className={"dottedLightGray"}></div>
                                                                        </>
                                                                        : ""}

                                                                    {clientDetails}
                                                                    <div className={"dottedLightGray"}></div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        {(theOrder.ord != null) ?
                                                            <div>
                                                                {/* <div className={"bold"}>
                                                                    אופן תשלום: {OrderFunctions.payingTYpe(theOrder)}
                                                                </div> */}
                                                                <div className={"bold"}>
                                                                    סטטוס תשלום: {theOrder.ord?.paymentStatus}
                                                                </div>
                                                                <div className={"big bold textLeft"}>
                                                                    {OrderFunctions.payingTYpe(theOrder)}: ₪{OrderFunctions.getOrderTotalPrice(theOrder.ord)}
                                                                </div>
                                                            </div>
                                                            : ""
                                                        }


                                                        {(theOrder.ord?.orderRemarks != null) ?
                                                            <div className={"pointing"} onClick={() => setRemarks()}>
                                                                <div className="veryBigFontImportant bold">{(theOrder.ord?.orderRemarks != null) ? parse(`${theOrder.ord.orderRemarks}`) : ""} </div>
                                                            </div>
                                                            : ""}
                                                    </div>

                                                </div>



                                                <div className={`${!mobiOrNot ? "noMargin" : "ordDetailsDiv"} col-md-6 onRight`}>

                                                    <div className="col-md-12 fullRow fullOnPrint">
                                                        <div className={`${!mobiOrNot ? "centerText" : "textRight"} bold`}>יצירת ההזמנה: {MyDateTime.getDateAndTime(theOrder.ord?.dateCreated)}</div>
                                                    </div>

                                                    {(theOrder.ord?.lastUpdate) ? <>
                                                        <div className="col-md-12 fullRow fullOnPrint">
                                                            <div className={`${!mobiOrNot ? "centerText" : "textRight"} bold`}>עדכון אחרון: {MyDateTime.getDateAndTime(theOrder.ord?.lastUpdate)}</div>
                                                        </div>
                                                        <div className=" col-md-12 fullRow fullOnPrint">
                                                            <div className={`${!mobiOrNot ? "centerText" : "textRight"} bold`}>עודכן ע"י: {theOrder.ord?.lastUpdaterBy || ""}</div>
                                                        </div>
                                                    </>
                                                        : ""}

                                                    {(theOrder.ord?.dateClosed) ? <>
                                                        <div className=" col-md-12 fullOnPrint">
                                                            <div className={`${!mobiOrNot ? "centerText" : "textRight"} bold`}>סגירת ההזמנה: {MyDateTime.getDateAndTime(theOrder.ord?.dateClosed)}</div>
                                                        </div>
                                                        <div className=" col-md-12 fullOnPrint">
                                                            <div className={`${!mobiOrNot ? "centerText" : "textRight"} bold`}>נסגר על ידי: {theOrder.ord?.closedBY}</div>
                                                        </div>
                                                    </>
                                                        : ""}
                                                </div>


                                            </div>

                                            {!mobiOrNot ?
                                                <div className={"ordDetailsDiv"}>
                                                    <InvoiceTable theOrder={theOrder} arabic={(arabic === "עברית") ? true : false} />
                                                </div>
                                                :
                                                <div className={"ordDetailsDiv"}>

                                                    <InvoiceMobiTable theOrder={theOrder} arabic={(arabic === "עברית") ? true : false} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                (arabic === "עברית") ? "" : <div className="ms-panel-header approveLine no_print">
                                    <button type="button" className="onLeft btn btn-primary spaceMargeTopbig" onClick={() => updateTheOrder()}>שמור</button>
                                </div>
                            }

                        </div>
                        {thePopUp}
                        {theSecondPop}
                    </div >

                    <div id={"printingCloser"} className="onlyOnPrint printingCloser">
                        <InvoicePrint theOrder={theOrder} arabic={(arabic === "עברית") ? true : false} />
                    </div>

                    <iframe id={"ifmcontentstoprint"}></iframe>



                    {/* <ArrowDropDownCircle className={`scrlBtn ${scrolBtnShow}`} onClick={() => scrolUp()} /> */}
                    <KeyboardCapslockOutlined className={`scrlBtn ${scrolBtnShow}`} onClick={() => scrolUp()} />

                </>


    );

}


export default MobiContent;
