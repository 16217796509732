import order from "../_ApiConn/apiOrder";


const orderMyFood = async (obj) => {
    let dFromF = await order.orderMyFood(obj);
    return (dFromF);
}

const getOrder = async (id) => {
    let token = localStorage.getItem("token");
    let dFromF = await order.getOrder(id, { token });
    return (dFromF);
}





const getOrderLog = async (orderNum) => {
    let token = localStorage.getItem("token");
    let dFromF = await order.getOrderLog({ orderNum, token });
    return (dFromF);
}

const getOrdersHistoryList = async (clientId) => {
    let token = localStorage.getItem("token");
    let dFromF = await order.getOrdersHistoryList({ clientId, token });
    return (dFromF);
}

const updateOrder = async (orderId, theOrder) => {

    let token = localStorage.getItem("token");
    let dFromF = await order.updateOrder(orderId, { ord: theOrder.ord, rst: theOrder.rst, usr: theOrder.usr, token });
    return (dFromF);
}

const getOrderBySearch = async (txtToSearch, startDTS, endDTS,byWhat) => {
    let token = localStorage.getItem("token");
    let dFromF = await order.getOrderBySearch({ txtToSearch, startDTS, endDTS, token,byWhat });
    return (dFromF);
}


const getAllOrders = async (startDTS, endDTS, ByRest) => {
    let token = localStorage.getItem("token");
    let dFromF = await order.getAllOrders({ startDTS, endDTS, ByRest, token });

    return (dFromF);
}

const getOrdByType = async (typ, startDTS, endDTS,) => {
    let token = localStorage.getItem("token");
    let dFromF = await order.getOrdersBy({ token, startDTS, endDTS, typ: [typ] });
    return (dFromF);
}

const getOpenOrders = async (startDTS, endDTS) => {
    let token = localStorage.getItem("token");
    let dFromF = await order.getOrdersBy({
        token, startDTS, endDTS, ordsBy:
            ["בהכנה", "ממתין לתגובה", "מוכן", "ממתין לאיסוף", "ממתין למשלוח", "נשלח", "נמסר ללקוח"]
    });
    return (dFromF);
}

const getClosedOrders = async (startDTS, endDTS) => {

    let token = localStorage.getItem("token");
    let dFromF = await order.getOrdersBy({ token, startDTS, endDTS, ordsBy: ["סגור", "מבוטל", "מושהה"] });
    return (dFromF);
}


const updateStatus = async (orderId, newStatus) => {

    let token = localStorage.getItem("token");
    let dFromF = await order.updateStatus({ orderId, newStatus, token });
    return (dFromF);
}

const updateStatusToCancel = async (orderId, newStatus, cancelReason) => {

    let token = localStorage.getItem("token");
    let dFromF = await order.updateStatusToCancel({
        orderId, newStatus,
        cancelReason, token
    });
    return (dFromF);
}



const updateStatusToList = async (theSelectsList, newStatus) => {

    let token = localStorage.getItem("token");
    let dFromF = await order.updateStatusToList({ theSelectsList, newStatus, token });
    return (dFromF);
}


const updateStatusToListToCancel = async (theSelectsList, newStatus, cancelReason) => {

    let token = localStorage.getItem("token");
    let dFromF = await order.updateStatusToListToCancel({
        theSelectsList, newStatus,
        cancelReason, token
    });
    return (dFromF);
}

const refreshList = async (theList) => {

    let token = localStorage.getItem("token");
    let dFromF = await order.refreshList({ theList, token });

    return (dFromF);
}



export default {
    getClosedOrders,
    getAllOrders,
    updateOrder,
    orderMyFood,
    getOrder,
    getOpenOrders,
    updateStatus,
    updateStatusToCancel,
    refreshList,
    getOrdByType,
    getOrderBySearch,
    getOrderLog,
    updateStatusToList,
    updateStatusToListToCancel,
    getOrdersHistoryList
};