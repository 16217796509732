import React, { Component, useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import OrderFunctions from '../../../../MyFunctions/OrderFunctions';
import MyDateTime from '../../../MySections/TimeSections/MyDateTime';
import OrderStatuselect from './OrderStatuselect';
import MultiLIstEditSelect from './MultiLIstEditSelect';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import { Checkbox } from '@mui/material';

import MultiUpdateCheck from '../../../MySections/PopUps/MultiUpdateCheck';
import { UserContext } from '../../../MyContexts/Users_Context';
import PopUpTheComp from '../../../MySections/DTechComps/PopUpTheComp';
import DeliveryDetails from '../DeliveryDetails/DeliveryDetails';
import myFunctions from '../../../../myFunctions';

const ListOrdersBlocks = (props) => {

    const [user, setUser] = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [multiSelect, setMultiSelect] = useState(props.theList.map((itm, indx) => { return { indx, checked: false, orderId: itm.ord._id } }));
    const [multiSelectStatus, setMultiSelectStatus] = useState("-1");
    const [selectAll, setSelectAll] = useState(false);
    const [thePopUp, setThePopUp] = useState("");



    useEffect(() => {
        setMultiSelect(props.theList.map((itm, indx) => { return { indx, checked: false, orderId: itm.ord._id } }))

    }, [props.theList])

    const orderBythis = (byWhat, ordUserOrBuiss) => {
        props.orderBythis(byWhat, ordUserOrBuiss);
    }

    const rowCellClick = (ordId, item) => {
        props.rowCellClick(ordId);
    }

    const setNewStatus = (orderId, statBack) => {
        props.setNewStatus(orderId, statBack);
    }


    const updateMulti = (i) => {
        let tempo = [...multiSelect];
        tempo[i].checked = !tempo[i].checked
        setMultiSelect(tempo)
        setSelectAll(false)
    }

    const updateSelAll = () => {
        let tempo = multiSelect.map((itm, indx) => { return { indx, checked: !selectAll, orderId: itm.orderId } });
        setMultiSelect(tempo)
        setSelectAll(!selectAll)
    }


    const checkApproveUpdate = () => {
        setThePopUp(<MultiUpdateCheck title={`האם בטוח לשנות את הנבחרים ל${multiSelectStatus}?`} sucecssFunc={() => updateTheSelected()} />)

    }

    const updateTheSelected = () => {

        setLoading(true)
        if (multiSelectStatus != "-1") {

            let theSelects = multiSelect.filter((it) => it.checked)

            if (theSelects != null) {
                props.setNewStatusToList(theSelects, multiSelectStatus)

            }
        }

        setLoading(false)

    }


    const PopDeliveryDetails = (orderNUM, buisName, AddrsTo) => {
        setThePopUp(
            <PopUpTheComp
                closePop={() => { setThePopUp() }}
                cssStyl={"OrderDispPop"}
                popDiagStyle={"OrderDispValPop"}
                errorMsg={""}
                okClick={() => { setThePopUp() }}
                btnClick1={true}
                Title={["פרטי השליחות"]}
                theInsideComp={
                    <DeliveryDetails cssStyl={"OrderDisplayPop"}
                        cancelClick={() => setThePopUp()}
                        okClick={() => { setThePopUp() }}
                        Title={[""]}
                        ordNum={orderNUM}
                        buisName={buisName}
                        AddrsTo={AddrsTo}
                    />}
            />

        )
    }

    return (
        <div className={`table-responsive theBigTbl ms-panel-body ordsDisp `}>
            <div className={"spaceMargeBottombig"}>
                <Checkbox checked={selectAll} onChange={() => { updateSelAll() }} className="chkBx" />הכל

                &nbsp;<MultiLIstEditSelect multiSelVal={multiSelectStatus}
                    changeToStatus={(statBack) => setMultiSelectStatus(statBack)}
                />
                <Button variant="contained" className={"theSBtn"} onClick={() => checkApproveUpdate()}
                    startIcon={<ModeEditIcon style={{ color: 'white' }} />}>
                    בצע
                </Button>

            </div>
            {

                props.theList.map((item, i) => {
                    let showme = (item.ord.paymentStatus !== "ממתין לחיוב") ?
                        true :
                        (user.permiss === "admin") ? true : false;
                    return (
                        <div key={item.ord._id} className={`${showme ? "" : "hideMe"} orderItminList big pointing ${item.ord.paymentStatus === "ממתין לחיוב" ? "tempoOrdRow" : ""}`}
                            itmid={item.ord._id} id={item.ord.orderNUM}>

                            <div className={`fullRow`} style={{ position: "relative" }} >
                                <Checkbox checked={multiSelect[i]?.checked || false} onChange={() => { updateMulti(i) }} className="chkBxSpec onRight" />

                                <div className={"timerTd onRight"} style={{ width: "50%" }} onClick={() => rowCellClick(item.ord._id, item)} id={i + "_Count"}>
                                    <div id={item.ord._id + "_Cnt"}>
                                        <br />
                                    </div>
                                </div>
                                <Button className={`btnMediumMnuSmall theSBtn onLeft`} style={{ width: "30%" }} variant="contained"
                                    onClick={() => rowCellClick(item.ord._id, item)}>
                                    הצג הזמנה
                                </Button>
                            </div>
                            <div className={`fullRow spaceTop`}>
                                <div style={{ width: "30%" }} className={"onRight"}>
                                    <div className={"onRight smallFont bold"}>#{item.ord.orderNUM}</div>
                                </div>

                                <div className={"onLeft"} style={{ width: "60%" }}>
                                    <div className={"onLeft smallFont bold"} style={{ marginLeft: "0px" }}>{MyDateTime.getShortTime(item.ord.dateCreated)}</div>
                                    <div className={"onLeft smallFont bold"}>{MyDateTime.getDate(item.ord.dateCreated)}</div>
                                </div>

                            </div>
                            <div className={`fullRow spaceMargeTop`}>

                                {
                                    (item.ord.OrderType === "Deliver") ?
                                        <div className={"fullRow spaceTop"}>
                                            <span className={"big bold onRight"}>משלוח:</span>
                                            <span className={"big bold onRight spaceMargeRightbig"}>
                                                {(!item.ord?.deliveryAddress.street.trim().endsWith(item.ord?.deliveryAddress.num)) ?
                                                    item.ord.deliveryAddress.street + " " + item.ord.deliveryAddress.num + " " + item.ord.deliveryAddress.city
                                                    : item.ord.deliveryAddress.street + " " + item.ord.deliveryAddress.city
                                                }
                                            </span>

                                        </div>
                                        :
                                        <span className={"big bold onRight"}>איסוף</span>
                                }

                            </div>

                            <div className={"fullRow spaceTop"}>
                                <div className={"onRight"}>
                                    <span className={"orderItmTxt smallFont"}>העסק:</span>&nbsp;
                                    <span className={"orderItmTxt"}>{item.buiss.buissName}</span>
                                </div>
                            </div>
                            <div className={"fullRow spaceTop"}>
                                <div className={"onRight"}>
                                    <span className={"orderItmTxt smallFont"}>הלקוח:</span>&nbsp;
                                    <span className={"orderItmTxt"}>{item.theUser.firstName} {item.theUser.lastName}</span>
                                </div>
                                <div className={"onLeft"}>
                                    <span className={"orderItmTxt smallFont"}>טל':</span>&nbsp;
                                    <span className={"orderItmTxt"}><a href={`tel:${item.theUser.phone}`}>{item.theUser.phone}</a></span>
                                </div>
                            </div>

                            <div className={"fullRow spaceMargeTopbig"}>
                                <div className={"orderItmTxt textLeft big bold"}>
                                    {`${OrderFunctions.payingTYpe(item)}`}: ₪{OrderFunctions.getOrderTotalPrice(item.ord)}</div>
                            </div>

                            <div className={"fullRow spaceMargeTopbig"}>
                                <div className={"statusCell pointing onRight"} style={{ width: "48%" }} >
                                    <OrderStatuselect orderStatus={item.ord.orderStatus}
                                        setOrderStatus={(statBack) => setNewStatus(item.ord._id, statBack)}
                                    />
                                </div>

                                {(item.ord.OrderType !== "Take" && item.ord?.sendiStatus && item.ord?.sendiStatus.trim() !== "") &&
                                    <div className={"onLeft sendiStatus"} style={{ width: "48%" }} >
                                        {(item.buiss?.sendiRestName && item.buiss?.sendiRestName != "") ?
                                            <Button className={`${myFunctions.getSendiColors(item.ord?.sendiStatus)} dvStat`} variant="contained"
                                                onClick={() => PopDeliveryDetails(item.ord.orderNUM, item.buiss.buissName,
                                                    item.ord.deliveryAddress.street + " " + item.ord.deliveryAddress.num + " " + item.ord.deliveryAddress.city)}>
                                                <span>{item.ord.sendiStatus}</span>
                                            </Button> :
                                            <Button className={`btnDeliveryDetail2 dvStat`} variant="contained"
                                                onClick={() => { }}>
                                                <span>שליח פרטי</span>
                                            </Button>
                                        }
                                    </div>
                                }
                            </div>

                        </div>
                    )

                }
                )
            }
            {thePopUp}

        </div >
    )
}

export default ListOrdersBlocks;